import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import shortid from "shortid"

import SidebarMenu from "../components/SidebarMenu"
import SEO from "../components/SEO.js"

const NewsCategory = ({
  data: { newsCategory, newsCategories, newsItems },
  pageContext: { locale, breadcrumbs, slug },
  location,
}) => {
  const meta = {
    title: newsCategory.data.meta_title,
    description: newsCategory.data.meta_description,
    keywords: newsCategory.data.meta_keywords,
    author: "Emil Mureșan",
  }

  const schema = {
    "@graph": [],
  }
  const ogp = []

  ogp.push({
    property: "og:image",
    content: newsCategory.data.parent_page.document.data.cover.fluid.src,
  })
  ogp.push({
    property: "og:image:alt",
    content: newsCategory.data.parent_page.document.data.cover.alt,
  })
  ogp.push({
    property: "og:image:width",
    content: newsCategory.data.parent_page.document.data.cover.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content:
      newsCategory.data.parent_page.document.data.cover.dimensions.height,
  })
  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  const categories = newsCategories.edges.map(r => r.node)
  const items = newsItems.edges.map(r => r.node)

  return (
    <>
      <SEO
        schema={JSON.stringify(schema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
      />
      <div id="news">
        <Img
          className="no-border full-image"
          alt={newsCategory.data.parent_page.document.data.cover.alt}
          fluid={newsCategory.data.parent_page.document.data.cover.fluid}
        />

        <div className="content">
          <h1>{newsCategory.data.title.text.toUpperCase()}</h1>

          <div className="list">
            {items.map(item => (
              <article key={shortid.generate()}>
                <div className="article-head">
                  <h2>
                    {item.data.title.text}
                    <span className="subtitle">
                      &nbsp;| {item.data.subtitle.text}
                    </span>
                  </h2>
                  <hr className="fancy" />
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: item.data.description.html,
                  }}
                />
              </article>
            ))}
          </div>
        </div>
      </div>

      <SidebarMenu
        items={categories}
        current={slug}
        breadcrumbs={breadcrumbs}
      />
    </>
  )
}

export default NewsCategory

export const query = graphql`
  query NewsCategoryQuery($slug: String!, $locale: String!) {
    newsCategory: prismicNewsCategory(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      data {
        meta_title
        meta_description
        meta_keywords
        title {
          text
        }
        parent_page {
          document {
            ... on PrismicNewsPage {
              data {
                cover {
                  alt
                  dimensions {
                    height
                    width
                  }
                  fluid {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }

    newsCategories: allPrismicNewsCategory(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            title {
              text
            }
            parent_page {
              document {
                ... on PrismicNewsPage {
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          uid
          lang
        }
      }
    }

    newsItems: allPrismicNewsItem(
      filter: {
        lang: { eq: $locale }
        data: { category: { uid: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          uid
          lang
          id
          data {
            description {
              html
            }
            subtitle {
              text
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`

NewsCategory.propTypes = {
  data: PropTypes.shape({
    newsCategory: PropTypes.object.isRequired,
    newsCategories: PropTypes.object.isRequired,
    newsItems: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
